import * as chrono from 'chrono-node';
import { DateTime } from 'luxon';
import { getLocale } from './locale.js';

export const dateDiffSeconds = (a: Date, b: Date): number =>
  DateTime.fromJSDate(a).diff(DateTime.fromJSDate(b), 'seconds').seconds;

const getChrono = (): chrono.Chrono => {
  const locale = getLocale();
  switch (locale) {
    case 'en-US':
      return chrono.casual;
    default: {
      const [language] = locale.split('-');
      switch (language) {
        case 'de':
          return chrono.de.casual;
        case 'es':
          return chrono.es.casual;
        case 'fe':
          return chrono.fr.casual;
        case 'ja':
          return chrono.ja.casual;
        case 'nl':
          return chrono.nl.casual;
        default:
          return chrono.en.GB;
      }
    }
  }
};

export const localeChrono = getChrono();
