const tokenOrder = [
  's',
  'short',
  'easy',
  'm',
  'med',
  'mid',
  'medium',
  'middle',
  'l',
  'long',
  'hard',
  'w',
  'white',
  'y',
  'yellow',
  'o',
  'orange',
  'o',
  'purple',
  'r',
  'red',
];

const compareTokens = (a: string[], b: string[], index: number): number => {
  if (index >= a.length) return a.length === b.length ? 0 : -1;
  if (index >= b.length) return 1;
  const aToken = a[index];
  const bToken = b[index];
  if (aToken === bToken) return compareTokens(a, b, index + 1);
  const aNumber = Number(aToken);
  const bNumber = Number(bToken);
  if (!Number.isNaN(aNumber) && !Number.isNaN(bNumber))
    return bNumber - aNumber;
  const aIndex = tokenOrder.indexOf(aToken);
  const bIndex = tokenOrder.indexOf(bToken);
  if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
  return aToken.localeCompare(bToken);
};

export const sortByCourseTitle = (
  a: { title: string },
  b: { title: string },
): number => {
  const aTokens = a.title.toLowerCase().split(/\s+/);
  const bTokens = b.title.toLowerCase().split(/\s+/);
  return compareTokens(aTokens, bTokens, 0);
};
